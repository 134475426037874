import { Stack, Text } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { MdnInput } from "../../../../common-ui";
import type { Task } from "../../../../sdk";
import { useSession, useSessionActiveCallerId } from "../../../../sdk";
import { formatPhoneNumber, useAsyncLoading, validatePhoneNumber } from "../../../../shared-utils";
import { useCallbacksFormStore } from "../../callbacks.store";
import { type CallbackFormVariant } from "../../types";
import { useScheduleCallback } from "../../useScheduleCallback";
import { CallbackActions } from "./CallbackActions";
import { CallbackTypeSelection } from "./CallbackTypeSelection";

interface ScheduleCallbackFormProps {
    shouldShowActions?: boolean;
}

function getFormVariant(activeTask: Task | undefined) {
    if (!activeTask) {
        return "post-call";
    }

    return activeTask.status === "wrapping" ? "post-call" : "active-call";
}

export function ScheduleCallbackForm({ shouldShowActions = true }: ScheduleCallbackFormProps) {
    const {
        selectedCallbackMdn,
        callbackScheduled,
        setCallbackType,
        setSelectedCallbackMdn,
        setSelectedCallbackNowDelay,
        selectedCallbackOption,
        resetState,
    } = useCallbacksFormStore();

    const session = useSession();

    const activeTask = session.currentTask;
    const { scheduleCallback } = useScheduleCallback();
    const activeSessionMdn = useSessionActiveCallerId();

    const defaultMdn = useMemo(
        () => (callbackScheduled && selectedCallbackMdn ? formatPhoneNumber(selectedCallbackMdn) : activeSessionMdn),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeSessionMdn],
    ); //? calculates once only when panel is mounted or activeSessionMdn changes

    const variant: CallbackFormVariant = getFormVariant(activeTask);
    const setDefaultValues = () => {
        switch (variant) {
            case "active-call":
                setCallbackType("callback-user-with-delay");
                break;
            case "post-call":
                setCallbackType("callback-user-now");
                setSelectedCallbackNowDelay(0);
                break;
        }
    };

    useEffect(() => {
        if (!callbackScheduled) {
            resetState();
            setDefaultValues();
            activeSessionMdn && setSelectedCallbackMdn(activeSessionMdn);
        } else if ((!selectedCallbackMdn || !validatePhoneNumber(selectedCallbackMdn).valid) && activeSessionMdn) {
            setSelectedCallbackMdn(activeSessionMdn);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSessionMdn]); //? runs only when panel is mounted or activeSessionMdn changes

    const [isSubmitting, onSubmit] = useAsyncLoading(async () => {
        await scheduleCallback();
    });

    return (
        <>
            <Stack gap="sm" mb="xl">
                <Text size="sm" fw="bold">
                    Callback number
                </Text>
                <MdnInput
                    defaultValue={defaultMdn}
                    onInput={(e) => setSelectedCallbackMdn(e.currentTarget.value)}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    maw="30rem"
                    mb="xs"
                    size="md"
                />
                <Text size="sm" fw="bold">
                    Callback time
                </Text>
                <CallbackTypeSelection onChange={setCallbackType} value={selectedCallbackOption} variant={variant} />
            </Stack>
            {shouldShowActions && (
                <CallbackActions
                    onSubmit={onSubmit}
                    isSubmitting={isSubmitting}
                    formVariant={variant}
                    data-testid="call-controls-callback-schedule-complete"
                />
            )}
        </>
    );
}
